<!--
<help text="Суммарное время проведенное<br/>на сервере" button />
<help text="Суммарное время проведенное<br/>на сервере" />

  components: {
    "help": () => import('@/site/componets/help'),
  },



-->


<template>
  <div>


    <v-icon v-if="button"
            size="medium" class="mr-2"
            style="opacity: 0.2"
            @click="show=!show"
    >
      mdi-help-circle-outline
    </v-icon>

    <v-tooltip bottom v-model="show">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs">
          <v-icon v-if="!button" v-on="on"
                  class="mr-2" size="medium"
                  style="opacity: 0.15">

            mdi-help-circle-outline
          </v-icon>
        </div>
      </template>

      <span v-html="text"/>

    </v-tooltip>


  </div>
</template>

<script>
export default {
  props: {
    text: {type: String},
    button: {type: Boolean, default: false}
  },
  data: function () {
    return {
      show: false,
    }
  },
}
</script>